
import { Component, Vue } from '~/utility/pu-class-decorator'

@Component
export default class SocialProof extends Vue {
  get feefoLink() {
    if (this.$i18n.locale === 'fr') {
      return 'https://ww2.feefo.com/reviews/pitchup-france'
    } else if (this.$i18n.locale === 'it') {
      return 'https://ww2.feefo.com/reviews/pitchup-italy'
    } else if (this.$i18n.locale === 'es') {
      return 'https://ww2.feefo.com/reviews/pitchup-spain'
    } else {
      return 'https://ww2.feefo.com/reviews/pitchup-com'
    }
  }
}
